import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useParams, useNavigate } from 'react-router-dom';
import { getEvent, updateEvent } from '../services/api'; // Import the update function from your API service

const UpdateEventForm = () => {
  const { id } = useParams();
  const eventId = id;
  const [event, setEvent] = useState(null);
  const [name, setName] = useState('');
  const [eventPIN, setEventPIN] = useState('');
  const [organizerEmail, setOrganizerEmail] = useState('');
  const [organizerPhone, setOrganizerPhone] = useState('');
  const [eventStartTime, setEventStartTime] = useState('');
  const [eventEndTime, setEventEndTime] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getEventDetails = async () => {
      try {
        const result = await getEvent(eventId);
        if (result && result._id) {
          const { name, eventPIN, organizerEmail, organizerPhone, eventStartTime, eventEndTime, description, location } = result;
          // Check if the event already ended
          if (dayjs().isAfter(dayjs(eventEndTime))) {
            alert('The event has already ended. You cannot update it.');
            navigate(`/event/${eventId}`);
            return;
          }

          setEvent(result);
          setName(name);
          setEventPIN(eventPIN);
          setOrganizerEmail(organizerEmail);
          setOrganizerPhone(organizerPhone);
          setEventStartTime(dayjs(eventStartTime).format('YYYY-MM-DDTHH:mm'));
          setEventEndTime(dayjs(eventEndTime).format('YYYY-MM-DDTHH:mm'));
          setDescription(description || '');
          setLocation(location || '');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    getEventDetails();
  }, [eventId, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!name || !eventPIN || !organizerEmail || !eventStartTime || !eventEndTime) {
      setErrorMessage('Please fill in all required fields.');
      return;
    }

    const pinRegex = /^\d{6,}$/;
    if (!pinRegex.test(eventPIN)) {
      setErrorMessage('PIN must be at least 6 digits.');
      return;
    }

    // Validate that eventStartTime and eventEndTime are within 48 hours of each other
    const start = dayjs(eventStartTime);
    const end = dayjs(eventEndTime);
    const duration = end.diff(start, 'hour');

    if (duration < 0 || duration > 48) {
      setErrorMessage('The event start time and end time must be within 48 hours of each other.');
      return;
    }



    // Prepare the event data for updating
    const eventData = {
      name,
      eventPIN,
      organizerEmail,
      organizerPhone,
      eventStartTime,
      eventEndTime,
      description,
      location,
    };

    try {
      const results = await updateEvent(eventId, eventData);
      if (results && results._id) {
        window.location.href = `/event/${results._id}`;
      }
    } catch (error) {
      console.error('Error updating event:', error);
      setErrorMessage('An error occurred while updating the event.');
    }
  };


  if (!event) return <div>Loading...</div>;

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-4 p-4 justify-center items-start">
      <div className="w-full lg:w-1/3 space-y-4 bg-gray-100 p-6 rounded-lg shadow-lg mt-6 lg:mt-0">
        <h3 className="text-lg font-bold text-gray-800">Instructions</h3>
        <ul className="list-disc list-inside text-gray-700">
          <li className='m-1 text-justify'>Update the event details as needed.</li>
          <li className='m-1 text-justify'>Ensure that the start and end times are correct.</li>
          <li className='m-1 text-justify'>You will receive a confirmation once the update is successful.</li>
        </ul>
      </div>
      <div className="w-full lg:w-2/3 space-y-4 bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold text-gray-800 text-center">Update Event</h2>

        {errorMessage && (
          <p className="text-red-500 mb-4">{errorMessage}</p>
        )}

        <label htmlFor="event-name" className="block">
          <span className="text-gray-700">Event Name *</span>
          <input
            id="event-name"
            type="text"
            placeholder="Event Name *"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>

        <label htmlFor="event-pin" className="block">
          <span className="text-gray-700">6-digit secure PIN *</span>
          <input
            id="event-pin"
            type="text" // Changed from "number" to "text" to use pattern validation
            placeholder="6-digit secure PIN *"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={eventPIN}
            onChange={(e) => setEventPIN(e.target.value)}
            pattern="\d{6,}" // Regex for 6 or more digits
            required
          />
        </label>

        <label htmlFor="organizer-email" className="block">
          <span className="text-gray-700">Organizer Email *</span>
          <input
            id="organizer-email"
            type="email"
            placeholder="Organizer Email *"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={organizerEmail}
            onChange={(e) => setOrganizerEmail(e.target.value)}
            required
          />
        </label>

        <label htmlFor="event-start-time" className="block">
          <span className="text-gray-700">Event Start Time *</span>
          <input
            id="event-start-time"
            type="datetime-local"
            placeholder="Event Start Time *"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={eventStartTime}
            onChange={(e) => setEventStartTime(e.target.value)}
            required
          />
        </label>

        <label htmlFor="event-end-time" className="block">
          <span className="text-gray-700">Event End Time *</span>
          <input
            id="event-end-time"
            type="datetime-local"
            placeholder="Event End Time *"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={eventEndTime}
            onChange={(e) => setEventEndTime(e.target.value)}
            required
          />
        </label>

        <label htmlFor="description" className="block">
          <span className="text-gray-700">Description (Optional)</span>
          <textarea
            id="description"
            placeholder="Description (Optional)"
            className="block w-full min-h-[75px] px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>

        <label htmlFor="location" className="block">
          <span className="text-gray-700">Location (Optional)</span>
          <input
            id="location"
            type="text"
            placeholder="Location (Optional)"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </label>

        <button
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={handleSubmit}
        >
          Update Event
        </button>
      </div>
    </div>
  );
};

export default UpdateEventForm;