import React from 'react';

function HowItWorks() {
  return (
    <section className="py-12 bg-gray-100 text-center">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">How It Works</h2>
          <p className="text-lg text-gray-600 mb-12">
             You're only 3 steps away from creating a fun experience for your audience.
             <br />
            
          </p>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-6">
          {/* Step 1 */}
          <div className="flex-1 bg-white shadow-lg rounded-lg p-6 mb-6 lg:mb-0">
            <div className="flex items-center justify-center mb-4">
              <div className="w-12 h-12 bg-purple-200 hover:bg-purple-500 text-white rounded-full flex items-center justify-center">
                <span className="text-xl font-bold">1</span>
              </div>
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Create an Event</h3>
            <p className="text-gray-600">
              Create an event in seconds. <br />Get an Event Code and a QR Code <br />
              example 1234
            </p>
          </div>

          {/* Step 2 */}
          <div className="flex-1 bg-white shadow-lg  rounded-lg p-6 mb-6 lg:mb-0">
            <div className="flex items-center justify-center mb-4">
              <div className="w-12 h-12 bg-purple-200 hover:bg-purple-500  text-white rounded-full flex items-center justify-center">
                <span className="text-xl font-bold">2</span>
              </div>
            </div>
            <h3 className="text-xl font-semibold  text-gray-800 mb-3">Share Your Event</h3>
            <p className="text-gray-600">
              Share the QR Code or Event Code with your attendees. <br />They can use it to join the event and interact with you.
            </p>
          </div>

          {/* Step 3 */}
          <div className="flex-1 bg-white shadow-lg rounded-lg p-6">
            <div className="flex items-center justify-center mb-4">
              <div className="w-12 h-12 bg-purple-200 hover:bg-purple-500 text-white rounded-full flex items-center justify-center">
                <span className="text-xl font-bold">3</span>
              </div>
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Manage Requests</h3>
            <p className="text-gray-600">
              Use your secure PIN to manage the event. <br />
              Approve Shout Outs, song requests, and more. 
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;