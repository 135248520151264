import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';


const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    navigate(`/event-code/${searchTerm}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }

  return (
    <div className="@container mx-auto max-w-[90%] text-center   items-center justify-center pt-6">
        <div className="[@480px]:p-4">
          <div
            className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat [@480px]:gap-8 [@480px]:rounded-xl items-center justify-center p-4"
            id='hero'>
          <h2
            className="text-white text-3xl font-black leading-tight tracking-[-0.033em] [@480px]:text-3xl [@480px]:font-black [@480px]:leading-tight [@480px]:tracking-[-0.033em] text-center uppercase opacity-70"
          >
            Elevate Your Event Experience
          </h2>
            <label className="flex flex-col min-w-40 h-12 w-full max-w-[480px] [@480px]:h-16">
              <div className="flex w-full flex-1 items-stretch rounded-xl h-full">
                <div
                  className="text-[#637588] flex border border-[#dce0e5] bg-white items-center justify-center pl-[15px] rounded-l-xl border-r-0"
                  data-icon="MagnifyingGlass"
                  data-size="20px"
                  data-weight="regular"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" viewBox="0 0 256 256">
                    <path
                      d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"
                    />
                  </svg>
                </div>
                <input
                  placeholder="Search by event ID"
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-[#111418] focus:outline-0 focus:ring-0 border border-[#dce0e5] bg-white focus:border-[#dce0e5] h-full placeholder:text-[#637588] px-[15px] rounded-r-none border-r-0 pr-2 rounded-l-none border-l-0 pl-2 text-sm font-normal leading-normal [@480px]:text-base [@480px]:font-normal [@480px]:leading-normal"
                  value={searchTerm}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <div className="flex items-center justify-center rounded-r-xl border-l-0 border border-[#dce0e5] bg-white pr-[7px]">
                  <button
                    className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 [@480px]:h-12 [@480px]:px-5 bg-[#197ce6] text-white text-sm font-bold leading-normal tracking-[0.015em] [@480px]:text-base [@480px]:font-bold [@480px]:leading-normal [@480px]:tracking-[0.015em]"
                    onClick={handleSearchClick}
                  >
                    <span className="truncate">find event</span>
                  </button>
                </div>
              </div>
            </label>
            
        <h3
          className="text-white text-2xl font-black leading-tight tracking-[-0.033em] [@480px]:text-2xl [@480px]:font-black [@480px]:leading-tight [@480px]:tracking-[-0.033em] text-center opacity-80"
        >
            a fun way to handle requests for <br /> shoutouts, songs, and more!
        </h3>
          </div>
        </div>
      </div>
  );
};

export default SearchBar;
