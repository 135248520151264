export const setItemWithExpiration = (key, value, expirationMinutes) => {
  const expirationTime = new Date().getTime() + expirationMinutes * 60 * 1000; // Expiration in milliseconds
  const item = {
    value: value,
    expiration: expirationTime
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiration = async (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const currentTime = new Date().getTime();

  if (currentTime > item.expiration) {
    // Item has expired
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};