import React, { useState } from 'react';
import { validatePin, forgotPin } from '../services/api';
import { setItemWithExpiration } from '../utils/localstorage';
import { Link } from 'react-router-dom';

const ManageEvent = ({ token, eventEnded, eventCode, id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pin, setPin] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const openPinPopup = () => setIsModalOpen(true);
  const closePinPopup = () => setIsModalOpen(false);

  const handlePinChange = (e) => setPin(e.target.value);

  const submitPin = async () => {
    if (!pin) {
      setErrorMessage('Please enter a PIN');
      return;
    }

    try {
      const result = await validatePin(id, pin);
      if (result.valid) {
        setItemWithExpiration(id, result.token, 24 * 60 * 60 * 1000); // Store token
        setIsModalOpen(false);
        window.location.href = `/event/${id}`;
      } else {
        setErrorMessage('Invalid PIN. Please try again.');
      }
    } catch (error) {
      console.error('Error validating PIN:', error);
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      submitPin();
    }
  };

  const handleForgotPin = async () => {
    await forgotPin(id);
    alert('PIN sent to your email');
    closePinPopup();
  }

  return (
    <div className="p-4" id="organizer-check-div">
      {!token && !eventEnded && (
        <div className="flex justify-center items-center">
          <div className="w-full max-w-md space-y-4 bg-white p-6 rounded-lg shadow-lg">
            <div className="text-center">
              <p className="text-lg font-semibold">Are you the event organizer?</p>
              <p className="text-gray-600">If you are the organizer, you can manage the event details using the PIN.</p>
            </div>
            <div className="flex justify-center">
              <button
                className="py-2 px-4 bg-blue-500 text-white rounded-md font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={openPinPopup}
              >
                Manage Event
              </button>
            </div>
          </div>
        </div>
      )}
      {token && !eventEnded && (

        <div className="flex justify-center items-center">
          <div className="w-full max-w-md space-y-4 bg-white p-6 rounded-lg shadow-lg">
            <div className="text-center">
              <p className="text-lg font-semibold">You are the event organizer</p>
            </div>
            <div className="flex justify-center">
              <Link
                to={`/update-event/${id}`}
                className="p-2 border border-gray-300 rounded-md text-[#066ffe] font-semibold hover:text-[#999999] hover:border-gray-500"
              >
                Make Changes to Event
              </Link>
            </div>
          </div>
        </div>
      )}
      {eventEnded && (

        <div className="flex justify-center items-center">
          <div className="w-full max-w-md space-y-4 bg-white p-6 rounded-lg shadow-lg">
            <div className="text-center">
              <p className="text-[#fe0606] text-[16px] font-bold text-center leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Event Ended <br /> Changes are not allowed</p>
            </div>
          </div>
        </div>
      )}

      {/* Popup Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-full max-w-sm bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Enter Event PIN</h2>
            {errorMessage && (
              <p className="text-red-500 mb-4 font-bold">{errorMessage}</p>
            )}
            <input
              type="text"
              placeholder="Enter PIN"
              value={pin}
              onKeyDown={handleKeyDown}
              onChange={handlePinChange}
              className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
            />
            <div className="flex justify-center gap-4">
              <button
                className="py-2 px-4 bg-blue-500 text-white rounded-md font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={submitPin}
              >
                Submit
              </button>
              <button
                className="py-2 px-4 bg-gray-300 text-gray-700 rounded-md font-semibold hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                onClick={closePinPopup}
              >
                Cancel
              </button>
            </div>
            <hr className='m-2' />
            <div className="flex justify-center p-4">

              <h4 className="text-gray-600 text-xs">Forgot PIN?</h4>
              </div>
            <div className="flex justify-center">
              <Link onClick={handleForgotPin} className="text-blue-600 text-sm">Click Here To Receive it in Email</Link>
              </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageEvent;