import React from 'react';
import { Link } from 'react-router-dom';

function CallToAction() {
  return (
    <section className="py-12 text-white text-center ">
      {/* <hr className='px-auto mx-auto p-5' /> */}
      <div className="max-w-4xl mx-auto  bg-yellow-500 m-5 p-5">
        <h2 className="text-3xl font-bold mb-4">Ready to Get Started?</h2>
        <p className="text-lg mb-6">Join the fun and start managing your events with Vybe.fm today.</p>
        <Link to="/new-event">
          <button className="bg-blue-700 px-6 py-3 rounded-full font-semibold hover:bg-blue-600">
            Create an Event
          </button>
        </Link>
      </div>
    </section>
  );
}

export default CallToAction;