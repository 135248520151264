import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

const EventQRCode = ({ eventId }) => {

  const eventLink = `${window.location.origin}/event-code/${eventId}`;
  return (
    <div className="flex flex-col items-center p-4 bg-white rounded-lg">
      <div className="text-center">
        <p className='text-md font-bold text-xl mb-1'>Event Code:</p>
        <p className='text-md font-bold text-xl text-blue-600 mb-4'>{eventId}</p>
      </div>

      {/* <h4 className="text-md font-bold text-gray-800 mb-4">Event QR Code</h4> */}

      <QRCodeSVG value={eventLink} size={128} className='p-1 shadow-lg' />
      <p className="mt-4 text-blue-600 text-xs">
        Scan the QR code to access the event
      </p>
      <p className="mt-4 text-gray-500 text-xs font-bold ">
         OR
      </p>
      <p className="mt-4 text-gray-600 text-xs ">
          use this URL: 
      </p>
      <p className="mt-4 text-black font-bold text-md hover:text-blue-600">
        {eventLink}
      </p>
    </div>
  );
};

export default EventQRCode;