import React, { useState, useEffect } from 'react';
import { getRequests } from '../services/api';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

function Requests() {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchRequests = async () => {
      const data = await getRequests('6535c811251126593089abd4');
      setRequests(Array.isArray(data) ? data : []);
    };
    fetchRequests();
  }, []);

  return (
    <div className="container">
      <div className="py-4">
        <div className="d-flex flex-column">
          <div className="d-flex flex-wrap justify-content-between mb-3">
            <div className="d-flex flex-column">
              <p className="h2 font-weight-bold text-dark">
                Event Requests
                {(requests.length > 0 && requests[0].event.name) ? (
                  <span className="text-secondary h6 font-weight-normal"> for {requests[0].event.name}</span>
                ) : null}
              </p>
              <p className="text-secondary h6 font-weight-normal">New requests will automatically show up here</p>
            </div>
          </div>

          {requests.length === 0 ? (
            <div className="d-flex justify-content-center py-5">
              <div className="text-center">
                <p className="h2 font-weight-bold text-dark">No requests found</p>
              </div>
            </div>
          ) : (
            <div className="request-list">
              {requests.map((request, index) => (
                <div key={index} className="request-item">
                  <div className="request-row">
                    <div className="request-label">User</div>
                    <div className="request-value">
                      <div
                        className="request-avatar"
                        style={{
                          backgroundImage:
                            'url("https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541")',
                        }}
                      ></div>
                      <span>{request.user.username}</span>
                    </div>
                  </div>
                  <div className="request-row">
                    <div className="request-label">Message</div>
                    <div className="request-value">{request.message}</div>
                  </div>
                  <div className="request-row">
                    <div className="request-label">Status</div>
                    <div className="request-value">{request.status}</div>
                  </div>
                  <div className="request-row">
                    <div className="request-label">Type</div>
                    <div className="request-value">{request.requestType}</div>
                  </div>
                  <div className="request-row">
                    <div className="request-label">Time</div>
                    <div className="request-value">{dayjs(request.createdAt).fromNow()}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Requests;