import React, { useState } from 'react';
import dayjs from 'dayjs';
import {newEvent} from '../services/api';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CreateEventForm() {
  const [name, setName] = useState('');
  const [eventPIN, setEventPIN] = useState('');
  const [organizerEmail, setOrganizerEmail] = useState('');
  const [organizerPhone, setOrganizerPhone] = useState('');
  const [eventStartTime, setEventStartTime] = useState('');
  const [eventEndTime, setEventEndTime] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const now = new Date();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate the form data
    if (!name || !eventPIN || !organizerEmail || !eventStartTime || !eventEndTime) {
      alert('Please fill in all required fields.');
      return;
    }

    const pinRegex = /^\d{6,}$/;
    if (!pinRegex.test(eventPIN)) {
      setErrorMessage('PIN must be at least 6 digits.');
      return;
    }

    // Validate that eventStartTime and eventEndTime are within 48 hours of each other
    const start = dayjs(eventStartTime);
    const end = dayjs(eventEndTime);
    const duration = end.diff(start, 'hour');


    if (duration < 0 || duration > 48) {
      alert('The event start time and end time must be within 48 hours of each other.');
      return;
    }

    // Submit the form data
    const eventData = {
      name,
      eventPIN,
      organizerEmail,
      organizerPhone,
      eventStartTime,
      eventEndTime,
      description,
      location,
    };
    console.log("eventData", eventData);

    const results = await newEvent(eventData);
    if (results && results.event) {
      alert(`Event created successfully. Event code: ${results.event.eventCode}`);
      // redirect to the event page
      window.location.href = `/event/${results.event._id}`;
    }

    // Clear the form fields after submission
    setName('');
    setEventPIN('');
    setOrganizerEmail('');
    setOrganizerPhone('');
    setEventStartTime('');
    setEventEndTime('');
    setDescription('');
    setLocation('');
  };

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-4 p-4 justify-center items-start">
      <div className="w-full lg:w-1/3 space-y-4 bg-gray-100 p-6 rounded-lg shadow-lg mt-6 lg:mt-0">
        <h3 className="text-lg font-bold text-gray-800">Instructions</h3>
        <ul className="list-disc list-inside text-gray-700">
          <li className='m-1 text-justify'>You will use a SECURE PIN to manage event</li>
          <li className='m-1 text-justify'>Email is used in case you forget the PIN</li>
          <li className='m-1 text-justify'>You will get an EVENT CODE that you share with your attendees</li>
          <li className='m-1 text-justify'>There will also be QR Code for easy access</li>
        </ul>
      </div>
      <div className="w-full lg:w-2/3 space-y-4 bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold text-gray-800 text-center">Create Event</h2>
        {errorMessage && (
          <p className="text-red-500 mb-4">{errorMessage}</p>
        )}
        <label className="block">
          <input
            type="text"
            placeholder="Event Name *"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>

        <label className="block">
          <input
            type="text"
            placeholder="6-digit secure PIN *"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={eventPIN}
            onChange={(e) => setEventPIN(e.target.value)}
            minLength={6}
            pattern="\d{6,}" // Regex for 6 or more digits
            required
          />
        </label>

        <label className="block">
          <input
            type="email"
            placeholder="Organizer Email *"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={organizerEmail}
            onChange={(e) => setOrganizerEmail(e.target.value)}
            required
          />
        </label>

        <DatePicker
          selected={eventStartTime}
          onChange={(date) => setEventStartTime(date)}
          showTimeSelect
          dateFormat="Pp"
          minDate={now}
          placeholderText="Event Start Time *"
          className="block sm:min-w-[300%] px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        /> 

  <br />
        <DatePicker
          selected={eventEndTime}
          onChange={(date) => setEventEndTime(date)}
          showTimeSelect
          dateFormat="Pp"
          minDate={eventStartTime}
          placeholderText="Event End Time *"
          className="block sm:min-w-[300%] px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />

        <label className="block">
          <textarea
            placeholder="Description (Optional)"
            className="block w-full min-h-[75px] px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>

        <label className="block">
          <input
            type="text"
            placeholder="Location (Optional)"
            className="block w-full px-3 py-2 border rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </label>

        <button
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={handleSubmit}
        >
          Create Event
        </button>
      </div>


    </div>
  );
}

export default CreateEventForm;