import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation, FindEvent, Footer, Requests, EventDetail, Login, NewEventForm, UpdateEventForm } from './components/index';
import './App.css';

function App() {
  return (
    <div className='app-container relative flex min-h-screen flex-col bg-white group/design-root overflow-x-hidden'>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<FindEvent />} />
          <Route path="/find-event" element={<FindEvent />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/event/:id" element={<EventDetail />} />
          <Route path="/event-code/:eventCode" element={<EventDetail />} />
          <Route path="/login" element={<Login />} />
          <Route path="/new-event" element={<NewEventForm />} />
          <Route path="/update-event/:id" element={<UpdateEventForm />} />
        </Routes>
        <Footer className='footer' />
      </Router>
    </div>
  );
}

export default App;