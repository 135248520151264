import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEvents, approveRequest, submitRequest, validateToken } from '../services/api';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import ManageEvent from './ManageEvent';
import { getItemWithExpiration } from '../utils/localstorage';
import EventQRCode from './EventQRCode';
import RequestsSection from './RequestsSection';

dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(duration);

function EventDetail() {
  let { id, eventCode } = useParams();

  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayName, setDisplayName] = useState('');
  const [requestMsg, setRequestMsg] = useState('');
  const [email, setEmail] = useState('');
  const [refetchEvent, setRefetchEvent] = useState(true);
  const [token, setToken] = useState(null);
  const [userSubmittedRequest, setUserSubmittedRequest] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [eventStarted, setEventStarted] = useState(false);
  const [eventEnded, setEventEnded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [event]);

  useEffect(() => {
    const fetchToken = async () => {
      const savedToken = await getItemWithExpiration(id);
      if (savedToken) {
        const result = await validateToken(id, savedToken);
        if (result && result.eventId === id) {
          setToken(savedToken);
        }
      }
    }
    fetchToken();
  }, [id, token]);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        let data;
        if (eventCode) {
          data = await getEvents({ eventCode });
        } else if (id) {
          data = await getEvents({ id });
        }
        data[0].requests.sort((a, b) => {
          if (a.status === 'pending') return -1;
          if (b.status === 'pending') return 1;
          if (a.status === 'approved') return -1;
          if (b.status === 'approved') return 1;
          return 0;
        });
        setEvent(data[0]);
        setRefetchEvent(false);
      } catch (error) {
        console.error('Error fetching event:', error);
      } finally {
        setLoading(false);
      }
    };

    if (refetchEvent) {
      fetchEvent();
    }

  }, [id, event, refetchEvent, eventCode]);

  useEffect(() => {
    const calculateCountdown = () => {
      if (!event) return;

      const now = dayjs();
      const startTime = dayjs(event.eventStartTime);
      const endTime = dayjs(event.eventEndTime);

      if (now.isBefore(startTime)) {
        const duration = dayjs.duration(startTime.diff(now));
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s until event starts`);
      } else if (now.isAfter(endTime)) {
        setEventEnded(true);
        setEventStarted(false);
        setCountdown(false);
      } else {
        const duration = dayjs.duration(endTime.diff(now));
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        setEventStarted(true);
        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s remaining`);
      }
    };

    calculateCountdown();
    const interval = setInterval(calculateCountdown, 1000);

    return () => clearInterval(interval);
  }, [event]);

  const handleApproveRequest = async (requestId) => {
    const token = await getItemWithExpiration(id);
    if (!token) {
      alert('You must be logged in to approve requests');
      return;
    }
    const results = await approveRequest({ requestId, token });
    if (results) {
      setEvent((prevEvent) => {
        const updatedRequests = prevEvent.requests.map((request) => {
          if (request._id === requestId) {
            return { ...request, status: results.status };
          }
          return request;
        });
        return { ...prevEvent, requests: updatedRequests };
      });
    }
  };

  const handleSubmitRequest = async () => {
    if (!email || !requestMsg) {
      alert('Please fill out all fields');
      return;
    }

    try {
      const response = await submitRequest({ email, displayName, message: requestMsg, eventId: id });
      console.log('Request submitted:', response);
      setDisplayName('');
      setRequestMsg('');
      setUserSubmittedRequest(true);
      setRefetchEvent(true);
    } catch (error) {
      console.error('Error submitting request:', error);
    }
  };

  const handleNewRequest = () => {
    setUserSubmittedRequest(false);
  };

  const isWithinEventTime = () => {
    if (!event) return false;
    const now = dayjs();
    const startTime = dayjs(event.eventStartTime);
    const endTime = dayjs(event.eventEndTime);
    return now.isBetween(startTime, endTime, null, '[]'); // inclusive
  };

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  if (!event) {
    return <div className="text-center py-4">Event not found</div>;
  }

  return (
    <div className="flex flex-col items-center py-5 md:max-w-[80%] min-w-[100%] mx-auto shadow-xl shadow-inner">
      <div className="flex flex-col gap-3 p-4  w-full md:w-auto">
        <div className="text-center mb-7">
          <p className="text-[#0d151c] text-2xl font-bold">{event.name}</p>
          {/* <hr /> */}
          <div className="flex justify-center items-center gap-2 mt-4 ">
          <p className="text-[#49779c] text-2xl p-4 mt-2">{event.description || 'No description available'}</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between md:items-start align-middle gap-4 h-full">
          {/* Left Column */}
          <div className="flex-1 bg-white p-4 rounded-lg shadow-inner drop-shadow-lg flex align-middle flex-col items-center justify-center h-full sm:min-h-[500px]">
            <div className="text-center align-middle">
              <EventQRCode eventId={event.eventCode} />
              {event && (
                <div className="py-4">
                  {(eventStarted && !eventEnded) && (
                    <p className="text-[#0d151c] text-xl font-bold">Event ends in:</p>
                  )}
                  {(!eventStarted && !eventEnded) && (
                    <p className="text-[#0d151c] text-xl font-bold">Event starts in:</p>
                  )}
                  {(eventEnded && !countdown) ? (
                    <p className="text-[#fe0606] text-lg font-bold">Event has ended</p>
                  ) : (
                  <p className="text-[#49779c] text-lg">{countdown}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Right Column */}
          <div className="flex-1 bg-white p-4 rounded-lg shadow-inner drop-shadow-lg flex flex-col align-middle justify-center h-full sm:min-h-[500px]">
            {(isWithinEventTime() && !token) ? (
              <div className="flex flex-col items-center">
                {userSubmittedRequest ? (
                  <button
                    className="w-full py-2 px-4 bg-blue-500 text-white rounded-md font-semibold hover:bg-blue-600"
                    onClick={handleNewRequest}
                  >
                    Submit a New Request
                  </button>
                ) : (
                  <div className="w-full">
                    <label htmlFor="email" className="block text-md font-bold">Send a request to the organizer</label>
                    <label className="block p-2">
                      <input
                        id="email"
                        placeholder="Your email *"
                        className="block w-full px-3 py-2 border rounded-md"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </label>
                    <label className="block p-2">
                      <input
                        id="displayName"
                        placeholder="Your display name (optional)"
                        className="block w-full px-3 py-2 border rounded-md"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </label>
                    <label className="block p-2">
                      <input
                        id="requestMsg"
                        placeholder="Type your request here *"
                        className="block w-full min-h-[75px] px-3 py-2 border rounded-md"
                        value={requestMsg}
                        onChange={(e) => setRequestMsg(e.target.value)}
                        maxLength={500}
                      />
                    </label>
                    <button
                      className="w-full py-2 px-4 bg-blue-500 text-white rounded-md font-semibold hover:bg-blue-600"
                      onClick={handleSubmitRequest}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center">
                <p className="text-[#49779c] text-lg p-4">Requests disabled until event starts</p>
              </div>
            )}
            
          </div>
        </div>
      </div>

      <hr className="my-4" />
      <RequestsSection event={event} handleApproveRequest={handleApproveRequest} token={token} />

      <div className="p-4" id="organizer-check-div">
        <ManageEvent token={token} eventEnded={eventEnded} eventCode={event.eventCode} id={id} />
      </div>
    </div>
  );
}

export default EventDetail;