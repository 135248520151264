import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

function EventsTable({ events }) {
  const navigate = useNavigate();

  const handleRowClick = async (id) => {
    navigate(`/event/${id}`);
  };

  return (
    <section className='pb-5'>
      <hr className='px-auto mx-auto p-5' />
      <div className="px-4 md:px-8 py-5">
        <div className="fle flx-col max-w-full lg:max-w-[960px] mx-auto">
          <div className=" gap-3 p-4">
            <h2 className="text-[#0d151c] text-3xl text-center font-bold leading-tight">
              Upcoming Events
            </h2>

          </div>

        </div>
        <div className="px-2 sm:px-4 py-3">
          <div className="flex justify-center">
            <div className="overflow-hidden rounded-xl border border-[#dce0e5] bg-white w-full max-w-screen-lg lg:max-w-[70%]">
              <table className="w-full">
                <thead>
                  <tr className="bg-white">
                    <th className="px-2 sm:px-4 py-3 text-left text-[#111418] text-xs sm:text-sm font-medium leading-normal">Name</th>
                    <th className="px-2 sm:px-4 py-3 text-left text-[#111418] text-xs sm:text-sm font-medium leading-normal">Date</th>
                    <th className="px-2 sm:px-4 py-3 text-left text-[#111418] text-xs sm:text-sm font-medium leading-normal">Requests</th>
                  </tr>
                </thead>
                <tbody>
                  {events.length === 0 && (
                    <tr className="border-t border-t-[#fff]">
                      <td className="h-[72px] px-2 sm:px-4 py-2 text-[#111418] text-xs sm:text-sm font-normal leading-normal" colSpan="3">
                        No events found
                      </td>
                    </tr>
                  )}
                  {events.map((event, index) => (
                    <tr
                      key={index}
                      className="border-t border-t-[#dce0e5] cursor-pointer hover:bg-gray-200 transition-colors"
                      onClick={() => handleRowClick(event._id)} // Replace with actual navigation
                    >
                      <td className="h-[72px] px-2 sm:px-4 py-2 text-[#111418] text-xs sm:text-sm font-normal leading-normal"> {event.name}</td>
                      <td className="h-[72px] px-2 sm:px-4 py-2 text-[#637588] text-xs sm:text-sm font-normal leading-normal">{dayjs(event.eventStartTime).fromNow()}</td>
                      <td className="h-[72px] px-2 sm:px-4 py-2 text-[#111418] text-xs sm:text-sm font-medium leading-normal">
                        {event.requests.length}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}

export default EventsTable;