import React, { useState, useEffect } from 'react';
import { getEvents } from '../services/api';

import SearchBar from './homepage_components/SearchBar';
import HowItWorks from './homepage_components/HowItWorks';
import CallToAction from './homepage_components/CallToAction';
import FeaturesSection from './homepage_components/FeaturesSection';
import EventsTable from './homepage_components/EventsTable';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

function FindEvent() {
  const [events, setEvents] = useState([]);


  useEffect(() => {
    const fetchEvents = async () => {
      const data = await getEvents({limit: 10, futureOnly: true});
      setEvents(Array.isArray(data) ? data : []);
    };
    fetchEvents();
  }, []);


  return (
    <div>
      <SearchBar />
      <HowItWorks />
      <FeaturesSection />
      <EventsTable events={events} />
      <CallToAction />
    </div>

  );
}

export default FindEvent;