import React, { useState } from 'react';
import dayjs from 'dayjs';
import { truncateEmail } from '../utils/emails';

function RequestsSection({ event, handleApproveRequest, token }) {
  const [statusFilter, setStatusFilter] = useState('pending'); // Default to 'pending'

  // Calculate counts for each status
  const counts = {
    open: event.requests.filter(request => request.status === 'pending').length,
    approved: event.requests.filter(request => request.status === 'approved').length,
    rejected: event.requests.filter(request => request.status === 'rejected').length,
  };

  // Filter requests based on the selected status
  const filteredRequests = event.requests.filter(request => request.status === statusFilter);

  return (
    <div className="flex flex-col min-w-full sm:min-w-[80%] p-4 shadow-md mt-4  ">
      <h2 className="text-[#0d151c] text-[22px] text-center font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
        Requests
      </h2>
      <div className="pb-3">
        <div className="flex flex-wrap border-b border-[#dce0e5] px-2 sm:px-4 gap-4 sm:gap-8">
          <a
            className={`flex-1 text-center border-b-2 sm:border-b-[3px] ${statusFilter === 'pending' ? 'border-[#111418] text-[#111418]' : 'border-transparent text-[#637588]'} pb-2 sm:pb-[13px] pt-2 sm:pt-4`}
            href="#open"
            onClick={() => setStatusFilter('pending')}
          >
            <p className="text-xs sm:text-sm font-bold leading-normal tracking-[0.015em]">
              Open: {counts.open}
            </p>
          </a>
          <a
            className={`flex-1 text-center border-b-2 sm:border-b-[3px] ${statusFilter === 'approved' ? 'border-[#111418] text-[#111418]' : 'border-transparent text-[#637588]'} pb-2 sm:pb-[13px] pt-2 sm:pt-4`}
            href="#approved"
            onClick={() => setStatusFilter('approved')}
          >
            <p className="text-xs sm:text-sm font-bold leading-normal tracking-[0.015em]">
              Approved: {counts.approved}
            </p>
          </a>
          <a
            className={`flex-1 text-center border-b-2 sm:border-b-[3px] ${statusFilter === 'rejected' ? 'border-[#111418] text-[#111418]' : 'border-transparent text-[#637588]'} pb-2 sm:pb-[13px] pt-2 sm:pt-4`}
            href="#rejected"
            onClick={() => setStatusFilter('rejected')}
          >
            <p className="text-xs sm:text-sm font-bold leading-normal tracking-[0.015em]">
              Rejected: {counts.rejected}
            </p>
          </a>
        </div>
      </div>
      {filteredRequests.length > 0 ? (
        filteredRequests.map((request) => (
          <div className="flex " key={request._id}>
            <div className="flex flex-1 flex-col items-stretch gap-2">
              <div className="flex flex-col gap-1">
                <div className="flex flex-wrap items-center gap-3">
                  <p className="text-[#0d151c] text-base font-bold leading-tight">
                    <i className="fa-solid fa-user text-2xl p-2"></i>
                    {request.displayName || truncateEmail(request.email) || 'Anonymous'}
                  </p>
                  <p className="text-[#49779c] text-sm font-normal leading-normal">
                    {dayjs(request.time).format('h:mm a')}
                  </p>
                </div>
                <p className="text-[#0d151c] text-base font-normal leading-normal">
                  {request.message}
                  {token && request.status === 'pending' && (
                    <button
                      className="flex min-w-[84px] max-w-[120px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-8 px-4 bg-[#eee] text-[#000] text-sm font-medium leading-normal"
                      onClick={() => handleApproveRequest(request._id)}
                    >
                      <span className="truncate">Approve</span>
                    </button>
                  )}
                  {request.status === 'approved' && (
                    <span className="text-green-500 ml-5">Approved</span>
                  )}
                  {request.status === 'rejected' && (
                    <span className="text-red-500 ml-5">Rejected</span>
                  )}
                </p>
              </div>

            </div>
          </div>
        ))
      ) : (
        <div className="p-4 text-center text-[#637588]">nothing here</div>
      )}
    </div>
  );
}

export default RequestsSection;