import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="navigation-header">
      <div className="navigation-container">
        <Link to="/">
          <div className="navigation-brand">
            <div className="logo">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round">
                <line x1="8" y1="32" x2="16" y2="32" />
                <line x1="16" y1="24" x2="24" y2="40" />
                <line x1="24" y1="16" x2="32" y2="48" />
                <line x1="32" y1="8" x2="40" y2="56" />
                <line x1="40" y1="16" x2="48" y2="48" />
                <line x1="48" y1="24" x2="56" y2="40" />
                <line x1="56" y1="32" x2="64" y2="32" />
              </svg>
            </div>
            <h2 className="brand-title">vybe.fm</h2>
          </div>
        </Link>
        <button className="hamburger-menu" onClick={toggleMenu}>
          <span className="hamburger-bar"></span>
          <span className="hamburger-bar"></span>
          <span className="hamburger-bar"></span>
        </button>
        <nav className={`navigation-links ${isMenuOpen ? 'open' : ''}`}>
          <hr className="mobile-separator" />
          <Link className="navigation-link" to="/" onClick={toggleMenu}>
            Home
          </Link>

          <Link className="bg-white hover:bg-red-500 hover:border-white hover:text-white text-red-500 font-semibold py-2 px-4 border border-red-500 rounded " to="/new-event" onClick={toggleMenu}>
            Create Event
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Navigation;