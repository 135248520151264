import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const getEvents = async (searchParams ) => {
  
  const params = new URLSearchParams();
  console.log(searchParams);
  if (searchParams && searchParams.eventCode) {
    params.append('eventCode', searchParams.eventCode);
  }
  if (searchParams && searchParams.id) {
    params.append('id', searchParams.id);
  }
  if (searchParams && searchParams.name) {
    params.append('name', searchParams.name);
  }
  if (searchParams && searchParams.limit) {
    params.append('limit', searchParams.limit);
  }
  if (searchParams && searchParams.futureOnly) {
    params.append('futureOnly', searchParams.futureOnly);
  }
  let URL = `${API_BASE_URL}/events?${params.toString()}`;
  console.log(URL);
  const response = await axios.get(URL);
  console.log("getEvents", response.data);
  if (Array.isArray(response.data)) {
    return response.data;
  } 
  return [response.data];
}

export const getEvent = async (eventId) => {
  const response = await axios.get(`${API_BASE_URL}/events/${eventId}`);
  console.log("getEvent", response.data);
  return response.data;
}

export const newEvent = async (data) => {
  const response = await axios.post(`${API_BASE_URL}/events`, data);
  return response.data;
}

export const updateEvent = async (eventId, data) => {
  const response = await axios.put(`${API_BASE_URL}/events/${eventId}`, data);
  console.log("updateEvent", response.data);
  return response.data;
}

export const getRequests = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/requests/event/${eventId}`);
    if (Array.isArray(response.data)) {
      return response.data;
    } 
    return []
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const approveRequest = async ({requestId, token}) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/requests/${requestId}/approve`, {
      token: token
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const submitRequest = async (data) => {
  const response = await axios.post(`${API_BASE_URL}/requests`, data);
  return response.data;
}


export const login = async (data) => {
  const response = await axios.post(`${API_BASE_URL}/auth/authenticate`, data);
  return response.data;
}

export const signup = async (data) => {
  const response = await axios.post(`${API_BASE_URL}/auth/register`, data);
  return response.data;
}

export const validatePin = async (eventId, pin) => {
  const response = await axios.post(`${API_BASE_URL}/events/validate-pin`, { eventId, eventPIN: pin });
  return response.data;
}

export const validateToken = async ( eventId, token) => {
  const response = await axios.post(`${API_BASE_URL}/events/validate-token`, {  eventId, token });
  return response.data.decoded;
} 

export const forgotPin = async (id) => {
  await axios.post(`${API_BASE_URL}/events/forgot-pin`, { id });
  return true;
}